import React from "react"
import { Link, graphql } from "gatsby"

import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const querymp = graphql`
  query NewsPageQuery($slug: String!) {
    strapiNewspage {
      strapiId
      title
      slug
    }
    allStrapiNewsPage(sort: { fields: [strapiId], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    strapiNewsPage(slug: { eq: $slug }) {
      title
      slug
      description
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: FULL_WIDTH
            )
          }
        }
      }
      content
    }
  }
`

const Page = ({ data }) => {
  const NewsPage = data.strapiNewspage
  const page = data.strapiNewsPage
  const pages = data.allStrapiNewsPage

  function markupNewsPageContent() {
    return { __html: page.content }
  }

  return (
    <Layout>
      <Seo
        title={page.title + " | Vacational Studies"}
        description={page.description}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg margin-bottom-sm">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Categories</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  <li className="subnav__item" key={`page__${NewsPage.slug}`}>
                    <Link className="subnav__link" to={`/${NewsPage.slug}`}>
                      {NewsPage.title}
                    </Link>
                  </li>
                  {pages.edges.map((newspage, i) => {
                    return (
                      <li
                        className="subnav__item"
                        key={`page__${newspage.node.slug}`}
                      >
                        <Link
                          className="subnav__link"
                          to={`/news/${newspage.node.slug}`}
                        >
                          {newspage.node.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div id="hero" className="container max-width-adaptive-lg margin-top-sm">
        <section
          className="bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${getSrc(
              page.hero.localFile.childImageSharp.gatsbyImageData
            )})`,
          }}
        >
          <div className="padding-y-xxxl"></div>
        </section>
      </div>

      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{page.title}</h1>
          <p className="text-md color-contrast-medium">{page.description}</p>
          <div dangerouslySetInnerHTML={markupNewsPageContent()} />
        </article>
      </div>
    </Layout>
  )
}

export default Page
